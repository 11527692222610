.App {
  margin: auto;
  min-width: 240px;
  max-width: 800px;
  /* border: 1px dotted #f0f; */
}

.onMobile .header {
  padding: 1rem 0 0.5rem 2rem;
}

.onDesktop .header {
  padding: 1rem 0 1rem 15rem;
}

.content {
  padding: 1rem;
}

footer {
  padding: 0 1rem 1rem 1rem;
  text-align: center;
  font-size: 0.75rem;
}

.center {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.margin-top {
  margin-top: 1rem;
}

.margin-bottom {
  margin-bottom: 1rem;
}

.dark {
  background-color: black;
  color: white;
}

.light {
  background-color: White;
  color: black;
}

.font1 {
  font-family: 'Rubik', sans-serif;
}

.font2 {
  font-family: 'Montserrat', sans-serif;
}

.font3 {
  font-family: 'SegoeUI', sans-serif;
}
