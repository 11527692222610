.LogoBoard {
}
.onMobile .LogoBoard {
  font-size: 1.5rem;
}
.onDesktop .LogoBoard {
  font-size: 2rem;
}

.LogoBoard .row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  /* border: 1px dotted #444; */
}

.LogoBoard .cell {
  white-space: nowrap;
}

.LogoBoard .fixed {
  white-space: nowrap;
  border-top: 1px transparent solid;
  border-left: 1px transparent solid;
  border-right: 1px #888 solid;
  border-bottom: 1px #888 solid;
}
.onMobile .LogoBoard .fixed {
  width: 1.05rem;
}
.onDesktop .LogoBoard .fixed {
  width: 1.5rem;
}

.LogoBoard .random {
  text-transform: uppercase;
  border-top: 1px transparent solid;
  border-bottom: 1px #888 solid;
  /* border-color: #f0d; */
}
